import { z } from 'zod';

import authService from '@/services/auth/auth';
import { capitalizeFirstLetter } from '@/utils/cvGenerator';

import { DjangoDataZodSchema } from './common';
import { apiRoutes } from './config';

export enum SkillTimeExperienceFormatOptions {
  YEAR_ROUND = 'YEAR_ROUND',
  YEAR_ROUND_UP = 'YEAR_ROUND_UP',
  YEAR_ROUND_DOWN = 'YEAR_ROUND_DOWN',
  MONTH = 'MONTH',
  YEAR_MONTH = 'YEAR_MONTH',
  FLOAT_YEAR = 'FLOAT_YEAR',
}

export enum SumTimeExperienceFormatOptions {
  YEAR_ROUND = 'YEAR_ROUND',
  YEAR_ROUND_UP = 'YEAR_ROUND_UP',
  YEAR_ROUND_DOWN = 'YEAR_ROUND_DOWN',
  MONTH = 'MONTH',
  YEAR_MONTH = 'YEAR_MONTH',
}

export enum DateFormatOptions {
  MONTH_YEAR_DOT = 'MONTH_YEAR_DOT',
  MONTH_YEAR = 'MONTH_YEAR',
  YEAR = 'YEAR',
  MONTH_NAME_YEAR = 'MONTH_NAME_YEAR',
  MONTH_SHORT_NAME_YEAR = 'MONTH_SHORT_NAME_YEAR',
  SOURCE = 'SOURCE',
  YEAR_MONTH_DOT = 'YEAR_MONTH_DOT',
  YEAR_MONTH = 'YEAR_MONTH',
}

export enum LanguageLevelPredefinedOptions {
  CEFR = 'CEFR',
  NUMERIC = 'NUMERIC',
  ADJECTIVE = 'ADJECTIVE',
  CUSTOM = 'CUSTOM',
}

export enum TranslateLanguages {
  ENGLISH = 'ENGLISH',
  POLISH = 'POLISH',
  SPANISH = 'SPANISH',
  RUSSIAN = 'RUSSIAN',
  FRENCH = 'FRENCH',
  GERMAN = 'GERMAN',
  ITALIAN = 'ITALIAN',
  PORTUGUESE = 'PORTUGUESE',
  UKRAINIAN = 'UKRAINIAN',
}

export enum Industries {
  IT = 'IT',
  ENGINEERING = 'ENGINEERING',
  FINANCIAL = 'FINANCIAL',
  EDUCATION = 'EDUCATION',
  HEALTHCARE = 'HEALTHCARE',
}

export const translateLanguagesOptions = Object.values(TranslateLanguages).map(
  (language) => ({
    value: language,
    label:
      language === 'ENGLISH'
        ? `${capitalizeFirstLetter(language)} (Default)`
        : capitalizeFirstLetter(language),
  })
);

export const industriesOptions = Object.values(Industries).map((industry) => ({
  value: industry,
  label:
    industry === 'IT'
      ? `${industry} (Default)`
      : capitalizeFirstLetter(industry),
}));

const optionalEmptyEnum = <T extends z.EnumLike>(enumObject: T) =>
  z.union([z.nativeEnum(enumObject), z.null(), z.literal('')]);

const SettingsSchema = z.object({
  dateFormat: optionalEmptyEnum(DateFormatOptions),
  sumTimeExperienceFormat: optionalEmptyEnum(SumTimeExperienceFormatOptions),
  languageLevelPredefined: optionalEmptyEnum(LanguageLevelPredefinedOptions),
  languageLevelCustom: z.object({
    basic: z.string().optional(),
    native: z.string().optional(),
    advanced: z.string().optional(),
    nearNative: z.string().optional(),
    intermediate: z.string().optional(),
  }),
  preferredCvLanguage: z.nativeEnum(TranslateLanguages),
  skillTimeExperienceFormat: optionalEmptyEnum(
    SkillTimeExperienceFormatOptions
  ),
  hideSkillsYearsOfExp: z.boolean(),
  groupSkills: z.boolean(),
  useAbbreviationsInSkills: z.boolean(),
  defaultTemplate: z.string(),
  logo: z.string().nullable(),
  skillsLevel: z.object({}),
  anonymizeEducation: z.boolean(),
  anonymizeLocation: z.boolean(),
  anonymizeName: z.boolean(),
  anonymizeProjects: z.boolean(),
  anonymizeWorkExperience: z.boolean(),
});

export type Settings = z.infer<typeof SettingsSchema>;

const BasicTemplateSchema = z.object({
  companyName: z.string(),
  email: z.string().email(),
  phone: z.string(),
  notes: z.string(),
  fontName: z.string(),
  title: z.string(),
  header1: z.string(),
  header2: z.string(),
  text: z.string(),
  caption: z.string(),
  sections: z.array(z.string()),
  extraSections: z.string(),
});

const GetFromAPIRequestTemplateSchema = BasicTemplateSchema.extend({
  id: z.number(),
  updatedAt: z.coerce.date(),
  createdAt: z.coerce.date(),
  templateFiles: z.array(z.string()),
  logoFile: z.string().nullable(),
  customFontFile: z.string().nullable(),
});

type BasicTemplate = z.infer<typeof BasicTemplateSchema>;
export type SendToAPIRequestTemplate = BasicTemplate & {
  templateFiles: (File | Blob)[];
  logoFile: File | Blob | null;
  customFontFile: File | Blob | null;
};
export type GetFromAPIRequestTemplate = z.infer<
  typeof GetFromAPIRequestTemplateSchema
>;

export const getSettings = async () => {
  const { data } = await authService.get(apiRoutes.settings.recruiter.mine);

  return SettingsSchema.parse({
    ...data,
    preferredCvLanguage: data.preferredCvLanguage ?? TranslateLanguages.ENGLISH,
  });
};

export const patchSettings = async (patchData: Partial<Settings>) => {
  const { data } = await authService.patch(
    apiRoutes.settings.recruiter.mine,
    patchData
  );
  return SettingsSchema.parse(data);
};

export const postTemplateRequest = async (data: SendToAPIRequestTemplate) => {
  const { data: responseData } = await authService.post(
    apiRoutes.settings.templateRequest._root,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  return GetFromAPIRequestTemplateSchema.parse(responseData);
};

export const patchTemplateRequest = async ({
  id,
  data,
}: {
  id: number;
  data: SendToAPIRequestTemplate;
}) => {
  const { data: responseData } = await authService.patch(
    apiRoutes.settings.templateRequest.id(id),
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  return GetFromAPIRequestTemplateSchema.parse(responseData);
};

export const getTemplateRequests = async () => {
  const { data } = await authService.get(
    apiRoutes.settings.templateRequest._root
  );
  return DjangoDataZodSchema(GetFromAPIRequestTemplateSchema).parse(data);
};

export const deleteTemplateRequest = async (id: number) =>
  authService.delete(apiRoutes.settings.templateRequest.id(id));
