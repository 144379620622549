import { z } from 'zod';

import authService from './auth/auth';
import { DjangoDataZodSchema } from './common';
import { apiRoutes } from './config';

const userApiKeySchema = z.object({
  prefix: z.string(),
  created: z.coerce.date(),
  name: z.string(),
  expiryDate: z.coerce.date().nullable(),
});

export const getApiKey = async (id: string) => {
  const { data } = await authService.get(apiRoutes.auth.apiKey.detail(id));
  return userApiKeySchema.parse(data);
};

export const getApiKeys = async () => {
  const { data } = await authService.get(apiRoutes.auth.apiKey._root, {
    params: { limit: 999 },
  });
  return DjangoDataZodSchema(userApiKeySchema).parse(data);
};

export const deleteApiKey = async (id: string) => {
  return authService.delete(apiRoutes.auth.apiKey.detail(id));
};

export const postApiKey = async (name: string) => {
  const { data } = await authService.post(
    apiRoutes.auth.apiKey._root,
    undefined,
    {
      params: { api_key_name: name },
    }
  );
  return z.object({ apiKey: z.string() }).parse(data);
};
