import type { UniqueIdentifier } from '@dnd-kit/core';
import type { SortingState } from '@tanstack/react-table';
import QueryString from 'qs';
import toast from 'react-hot-toast';
import { string } from 'yup';
import { z } from 'zod';

import authService from '@/services/auth/auth';

import { DjangoDataZodSchema } from './common';
import { apiRoutes } from './config';
import { CvContextListElementSchema } from './cvGenerator';
import type { OfferSearch } from './reasoningSearch';

export enum SeniorityLevelEnum {
  INTERN = 'INTERN',
  JUNIOR = 'JUNIOR',
  MID = 'MID',
  SENIOR = 'SENIOR',
  LEAD = 'LEAD',
  NOT_SPECIFIED = '',
}

export const experienceOptions = [
  {
    value: 'INTERN',
    label: 'Intern',
  },
  {
    value: 'JUNIOR',
    label: 'Junior',
  },
  { value: 'MID', label: 'Regular' },
  {
    value: 'SENIOR',
    label: 'Senior',
  },
  { value: 'LEAD', label: 'Manager' },
];

export const jobStartOptions = [
  { value: 'ASAP', label: 'ASAP' },
  { value: 'UP_TO_ONE_MONTH', label: 'Up to 1 Month' },
  { value: 'BETWEEN_ONE_AND_THREE_MONTHS', label: 'Over 1 Month' },
];

export const availabilityOptions = [
  { value: 'FULL_TIME', label: 'Full-time' },
  { value: 'PART_TIME', label: 'Part-time' },
  { value: 'CONSULTANT', label: 'Consultant' },
];

const noteSchema = z.object({
  id: z.number(),
  text: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export type Note = z.infer<typeof noteSchema>;

const candidateDetailedInfoSchema = z.object({
  availability: z.string(),
  canRelocate: z.boolean().nullable(),
  city: z.number().nullable(),
  country: z.number().nullable(),
  jobStart: z.string().nullable(),
  seniorityLevel: z.nativeEnum(SeniorityLevelEnum).nullable(),
  skills: z.array(z.string()),
  jobTitle: z.string().nullable(),
});

export const externalCandidateListSchema = z.object({
  id: z.number(),
  name: z.string(),
  email: z.string(),
  candidateDetailedInfo: candidateDetailedInfoSchema.nullable(),
  languages: z.array(z.object({ language: z.number(), level: z.number() })),
  phoneNumber: z.string(),
  cvFile: z.string().nullable(),
  collections: z.array(z.object({ id: z.number(), title: z.string() })),
  cvContexts: z.array(CvContextListElementSchema),
  updatedAt: z.coerce.date(),
  createdAt: z.coerce.date(),
  status: z.enum(['PROCESSING', 'READY', 'FAILED']),
  notes: z.array(noteSchema),
  linkedinUrl: z.string(),
  githubUrl: z.string(),
  convertedSalary: z.number().nullable(),
  salary: z.number().nullable(),
  currency: z.enum(['PLN', 'EUR', 'USD']),
  paymentPeriod: z.enum(['HOUR', 'MONTH', 'YEAR']),
});

export type ExternalCandidateList = z.infer<typeof externalCandidateListSchema>;

const githubProfileRepoSchema = z.object({
  name: z.string(),
  description: z.string().nullable(),
  htmlUrl: z.string(),
  stars: z.number(),
  watchers: z.number(),
  forks: z.number(),
  programmingLanguages: z.record(z.string(), z.number()),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  pushedAt: z.coerce.date(),
});

export type GithubProfileRepo = z.infer<typeof githubProfileRepoSchema>;

const githubProfileSchema = z.object({
  status: z.enum(['PROCESSING', 'READY', 'FAILED']),
  errorType: z.enum([
    'GITHUB_API',
    'WRONG_USERNAME',
    'INTERNAL',
    'WRONG_API_AUTH_CREDENTIALS',
    '',
  ]),
  name: z.string(),
  company: z.string(),
  email: z.string(),
  location: z.string(),
  bio: z.string(),
  hireable: z.boolean().nullable(),
  repositoriesUrl: z.string(),
  publicRepositoriesNumber: z.number().nullable(),
  publicGistsNumber: z.number().nullable(),
  summedRepositoriesStars: z.number().nullable(),
  totalContributions: z.number().nullable(),
  followers: z.number().nullable(),
  following: z.number().nullable(),
  githubCreatedAt: z.coerce.date(),
  githubUpdatedAt: z.coerce.date(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export type GithubProfile = z.infer<typeof githubProfileSchema>;

export const externalCandidateSchema = z.object({
  id: z.number(),
  name: z.string(),
  email: z.string(),
  phoneNumber: z.string(),
  cvFile: z.string().nullable(),
  collections: z.array(z.object({ id: z.number(), title: z.string() })),
  cvContexts: z.array(CvContextListElementSchema),
  updatedAt: z.coerce.date(),
  createdAt: z.coerce.date(),
  status: z.enum(['PROCESSING', 'READY', 'FAILED']),
  notes: z.array(noteSchema),
  generationType: z.enum(['RECRUITEE_ID', 'FILE']),
  recruiteeId: z.number().nullable(),
  startDate: z.coerce.date().nullable(),
  salary: z.number().nullable(),
  paymentPeriod: z.enum(['HOUR', 'MONTH', 'YEAR', '']),
  currency: z.enum(['PLN', 'EUR', 'USD', '']),
  linkedinUrl: z.string(),
  githubUrl: z.string(),
  githubProfile: githubProfileSchema.nullable(),
  city: z.number().nullable(),
  country: z.number().nullable(),
  languages: z.array(
    z.object({ language: z.number(), level: z.number().nullable() })
  ),
  canRelocate: z.boolean().nullable().optional(),
  skills: z.array(z.string()).optional(),
  seniorityLevel: z.string(),
  availability: z.string(),
  jobStart: z.string(),
});

export type ExternalCandidate = z.infer<typeof externalCandidateSchema>;

export const candidateCollectionSchema = z.object({
  id: z.number(),
  title: z.string(),
  description: z.string(),
  candidates: z.array(externalCandidateSchema),
});

export type ExternalCandidatesCollection = z.infer<
  typeof candidateCollectionSchema
>;

const csvDataSchema = z.array(z.array(z.string()));

export type CsvData = z.infer<typeof csvDataSchema>;

const csvPreviewSchema = z.object({
  columns: z.array(z.string()),
  mappedColumns: z.array(
    z.object({
      originalName: z.string(),
      mappedIndex: z.number(),
      mappedName: z.string(),
    })
  ),
  numberOfRows: z.number(),
  csvId: z.number(),
});

export type CsvPreview = z.infer<typeof csvPreviewSchema>;

const importProgressSchema = z.object({
  processing: z.number(),
  added: z.number(),
  errors: z.number(),
});

export type Field = {
  value: string;
  label: string;
  columnId: UniqueIdentifier | null;
  required?: boolean;
  aiSuggested?: boolean;
  duplicatedId?: string;
};

const transformApiCandidateResponse = (data: ExternalCandidate) => ({
  ...data,
  languages: data.languages.map(({ language, level }) => [language, level]) as [
    number,
    number
  ][],
  availability: data.availability || undefined,
  jobStart: data.jobStart || undefined,
  seniorityLevel: data.seniorityLevel || undefined,
});

const transformCandidateData = (
  data: Partial<ReturnType<typeof transformApiCandidateResponse>>
) => ({
  ...data,
  languages: data.languages?.map(([language, level]) => ({ language, level })),
});

export const postCvConstruct = async (formData: {
  cvData: {
    cv?: File;
    id?: string;
  };
  language: string;
  industry: string;
  anonymization: boolean;
  generateResume: boolean;
  useExternalApi?: boolean;
  forceGenerate?: boolean;
  offerDescription?: string;
  offerTitle?: string;
  fieldsToAdjustToOffer?: string[];
  collections?: number[];
}) => {
  const requestBody = {
    language: formData.language,
    offer_title: formData.offerTitle,
    offer_description: formData.offerDescription,
    fields_to_adjust_to_offer: formData.fieldsToAdjustToOffer,
    collections: formData.collections,
    industry: formData.industry,
  };
  const postCv = async () =>
    authService.post(
      apiRoutes.externalCandidates.candidates.fromFile,
      { cv_file: formData.cvData.cv, ...requestBody },
      {
        params: {
          force_generate: formData.forceGenerate,
          generate_cv_context: formData.generateResume,
          skip_anonymization: !formData.anonymization,
          use_external_api: formData.useExternalApi,
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  const postCvFromId = async () =>
    authService.post(
      apiRoutes.externalCandidates.candidates.fromRecruiteeId,
      { recruitee_id: formData.cvData.id, ...requestBody },
      {
        params: {
          forceGenerate: formData.forceGenerate,
          generate_cv_context: formData.generateResume,
          skip_anonymization: !formData.anonymization,
        },
      }
    );

  const { data, status } = await (formData.cvData.id
    ? postCvFromId()
    : postCv());
  const validData = externalCandidateSchema.parse(data);
  if (status === 220)
    toast.error(
      'We uploaded your candidate to database, but unfortunately you exceeded the limit of generated Resumes.'
    );
  return validData;
};

export const getExternalCandidates = async ({
  offset,
  pageSize,
  search,
  collections,
  salary_order,
  target_currency,
  target_period,
  filtersId,
  showSuggestedCandidates,
  filters,
}: {
  offset?: number;
  pageSize?: number;
  search?: string;
  collections?: number[];
  salary_order?: SortingState;
  target_currency?: string;
  target_period?: string;
  filtersId?: number;
  showSuggestedCandidates?: boolean;
  filters?: OfferSearch['aiParsedFilters'];
}) => {
  const filtersParams = (() => {
    if (!filters) return {};
    const {
      jobStart,
      seniority,
      languages,
      city,
      country,
      relocation,
      ...rest
    } = filters;
    return {
      job_start: jobStart,
      seniority_level: seniority,
      language_skills: languages?.map(({ id, level }) => `${id},${level}`),
      city: city.map(({ value }) => value),
      country: country.map(({ value }) => value),
      relocation:
        relocation?.length === 0 || relocation?.length === 2
          ? undefined
          : !!relocation?.includes('Yes'),
      ...rest,
    };
  })();

  const { data } = await authService.get(
    filtersId
      ? apiRoutes.search.reasoningSearch.candidates.detail(filtersId)
      : apiRoutes.externalCandidates.candidates._root,
    {
      params: filtersId
        ? {
            offset,
            limit: pageSize,
            show_suggested_candidates: showSuggestedCandidates,
            collections,
          }
        : {
            offset,
            limit: pageSize,
            search,
            collections,
            target_currency,
            target_period,
            salary_order: salary_order?.map(
              ({ desc, id }) => `${desc ? '-' : ''}${id}`
            ),
            ...filtersParams,
          },
      paramsSerializer: (params) =>
        QueryString.stringify(params, { arrayFormat: 'repeat' }),
    }
  );
  const validatedData = DjangoDataZodSchema(externalCandidateListSchema)
    .passthrough()
    .parse(data);

  return validatedData;
};

export const getExternalCandidate = async (id: number) => {
  const { data } = await authService.get(
    apiRoutes.externalCandidates.candidates.detail(id).root
  );
  const validatedData = externalCandidateSchema.parse(data);
  return transformApiCandidateResponse(validatedData);
};
export const getExternalCandidatesCollections = async () => {
  const { data } = await authService.get(
    apiRoutes.externalCandidates.collections._root,
    { params: { offset: 0, limit: 9999 } }
  );
  return DjangoDataZodSchema(candidateCollectionSchema).parse(data);
};

export const postExternalCandidatesCollection = async ({
  description,
  title,
  candidates,
}: {
  title: string;
  description: string;
  candidates?: number[];
}) => {
  const { data } = await authService.post(
    apiRoutes.externalCandidates.collections._root,
    {
      title,
      description,
      candidates,
    }
  );
  return candidateCollectionSchema.parse(data);
};

export const patchCollection = async ({
  data,
}: {
  data: {
    id: number;
    data: Partial<{ name: string; description: string; candidates: number[] }>;
  }[];
}) => {
  return Promise.all(
    data.map(({ data: collectionData, id }) =>
      authService.patch(
        apiRoutes.externalCandidates.collections.detail(id),
        collectionData
      )
    )
  );
};

export const patchExternalCandidate = async ({
  id,
  data,
}: {
  id: number;
  data: Partial<
    Omit<
      ReturnType<typeof transformApiCandidateResponse>,
      | 'id'
      | 'status'
      | 'generationType'
      | 'recruiteeId'
      | 'cvFile'
      | 'cvContext'
      | 'updatedAt'
      | 'createdAt'
      | 'collections'
      | 'notes'
    > & { collections: number[] } & { notes: { text: string }[] }
  >;
}) => {
  const { collections, notes, ...profileData } = data;

  return authService.patch(
    apiRoutes.externalCandidates.candidates.detail(id).root,
    { ...transformCandidateData(profileData), collections, notes }
  );
};

export const deleteExternalCandidates = async (ids: number[]) => {
  return Promise.all(
    ids.map((id) =>
      authService.delete(
        apiRoutes.externalCandidates.candidates.detail(id).root
      )
    )
  );
};

export const deleteExternalCandidatesCollection = async (id: number) =>
  authService.delete(apiRoutes.externalCandidates.collections.detail(id));

export const postRetryExternalCandidate = async (id: number) =>
  authService.post(apiRoutes.externalCandidates.candidates.detail(id).retry);

export const postCSV = async (file: File) => {
  const { data } = await authService.post(
    apiRoutes.externalCandidates.csv._root,
    {
      file,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  return csvPreviewSchema.parse(data);
};

export type CSVColumns = {
  url_column_index: number;
  name_column_index?: number;
  first_name_column_index?: number;
  last_name_column_index?: number;
  email_column_index?: number;
  phone_column_index?: number;
  linkedin_column_index?: number;
  github_column_index?: number;
  other_column_indexes?: number;
};

export const postUploadCSV = async ({
  id,
  ...formData
}: {
  id: number;
  isHeader?: boolean;
  collections?: number[];
} & CSVColumns) => {
  const { data } = await authService.post(
    apiRoutes.externalCandidates.csv.detail(id).uploadCsv,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  );
  return string().defined().validate(data);
};

export const getImportProgress = async (id: number) => {
  const { data } = await authService.get(
    apiRoutes.externalCandidates.csv.detail(id).progress
  );
  return importProgressSchema.parse(data);
};

export const getMoreRows = async ({
  id,
  offset,
  pageSize,
  isHeader,
}: {
  id: number;
  offset?: number;
  pageSize?: number;
  isHeader?: boolean;
}) => {
  const { data } = await authService.get(
    apiRoutes.externalCandidates.csv.detail(id)._root,
    { params: { offset, limit: pageSize, is_header: isHeader } }
  );
  return z.object({ data: csvDataSchema }).parse(data);
};

export const getImportErrorsData = async (id: number) => {
  const { data } = await authService.get(
    apiRoutes.externalCandidates.csv.detail(id).errorsData
  );
  const validatedData = z
    .array(
      z.object({
        positionInCsv: z.number(),
        name: z.string(),
        errorType: z.string(),
      })
    )
    .parse(data);
  return {
    columns: ['Row in original file', 'Name', 'Error'],
    data: validatedData.map(({ errorType, name, positionInCsv }) => [
      positionInCsv,
      name,
      errorType,
    ]),
  };
};

export const postFetchGithubData = async (id: number) => {
  const { data } = await authService.post(
    apiRoutes.externalCandidates.candidates.detail(id).github._root
  );
  return externalCandidateSchema.parse(data);
};

export const getGithubRepositories = async ({
  id,
  offset,
  pageSize,
}: {
  id: number;
  offset?: number;
  pageSize?: number;
}) => {
  const { data } = await authService.get(
    apiRoutes.externalCandidates.candidates.detail(id).github.repositories,
    {
      params: {
        offset,
        limit: pageSize,
      },
    }
  );
  return z
    .object({
      count: z.number(),
      results: z.array(githubProfileRepoSchema),
      status: z.enum(['PROCESSING', 'READY', 'FAILED']),
    })
    .parse(data);
};
