import type { AxiosRequestConfig } from 'axios';
import QueryString from 'qs';
import type { InferType } from 'yup';
import { array, boolean, number, object, string } from 'yup';
import { z } from 'zod';

import authService from '@/services/auth/auth';

import { DjangoDataZodSchema } from './common';
import { apiRoutes } from './config';
import type { TranslateLanguages } from './settings';

export enum AdjustToOfferFields {
  'Job title' = 'JOB_TITLE',
  'Summary' = 'SUMMARY',
  'Languages' = 'LANGUAGES',
  'Hard skills' = 'HARD_SKILLS',
}
export enum Themes {
  LIGHT = 'WHITE',
  DARK = 'PURPLE',
  PRIMOTLY = 'PRIMOTLY',
  ONLINECH = 'ONLINECH',
  SOFTBLUE = 'SOFTBLUE',
  SIGMAITNEXER = 'SIGMAITNEXER',
  NEXERFLEXIBLE = 'NEXERFLEXIBLE',
  HOLISTICONFLEXIBLE = 'HOLISTICONFLEXIBLE',
  HOLISTICON = 'HOLISTICON',
  BILLENNIUM = 'BILLENNIUM',
  INETUM = 'INETUM',
  HAPPYTEAM = 'HAPPYTEAM',
  ITEO = 'ITEO',
  WORKITNOW = 'WORKITNOW',
  MINDPALPRO = 'MINDPALPRO',
  SOFTHOUSE = 'SOFTHOUSE',
  PUREVALUE = 'PUREVALUE',
  PUREVALUE_EN = 'PUREVALUEEN',
  AVATARINTERNATIONAL = 'AVATARINTERNATIONAL',
  PEOPLEMORE = 'PEOPLEMORE',
  WHITEBETA = 'WHITEBETA',
}

export const themesOptions = [
  { label: 'White', value: Themes.LIGHT, domain: '' },
  { label: 'New White Beta', value: Themes.WHITEBETA, domain: 'mindpal.co' },
  { label: 'Inetum', value: Themes.INETUM, domain: 'inetum.com' },
  { label: 'MindPal Purple', value: Themes.DARK, domain: '' },
  { label: 'MindPal Pro', value: Themes.MINDPALPRO, domain: 'mindpal.co' },
  { label: 'Happy Team', value: Themes.HAPPYTEAM, domain: 'happyteam.io' },
  { label: 'Billennium', value: Themes.BILLENNIUM, domain: 'billennium.com' },
  { label: 'Primotly', value: Themes.PRIMOTLY, domain: 'primotly.com' },
  { label: 'Iteo', value: Themes.ITEO, domain: 'iteo.com' },
  {
    label: 'Online macht digital.',
    value: Themes.ONLINECH,
    domain: 'online.ch',
  },
  { label: 'WORKITNOW', value: Themes.WORKITNOW, domain: 'workitnow.pl' },
  { label: 'SoftBlue', value: Themes.SOFTBLUE, domain: 'softblue.pl' },
  {
    label: 'NEXER',
    value: Themes.SIGMAITNEXER,
    domain: 'holisticon.pl',
  },
  {
    label: 'NEXER',
    value: Themes.SIGMAITNEXER,
    domain: 'nexergroup.com',
  },
  {
    label: 'NEXER Flexible',
    value: Themes.NEXERFLEXIBLE,
    domain: 'nexergroup.com',
  },
  {
    label: 'NEXER Flexible',
    value: Themes.NEXERFLEXIBLE,
    domain: 'holisticon.pl',
  },
  {
    label: 'Holisticon Flexible',
    value: Themes.HOLISTICONFLEXIBLE,
    domain: 'holisticon.pl',
  },
  {
    label: 'Holisticon Flexible',
    value: Themes.HOLISTICONFLEXIBLE,
    domain: 'nexergroup.com',
  },
  { label: 'Holisticon', value: Themes.HOLISTICON, domain: 'holisticon.pl' },
  { label: 'Holisticon', value: Themes.HOLISTICON, domain: 'nexergroup.com' },
  { label: 'SOFT HOUSE', value: Themes.SOFTHOUSE, domain: 'soft-house.pl' },
  { label: 'Pure Value PL', value: Themes.PUREVALUE, domain: 'purevalue.pl' },
  {
    label: 'Pure Value EN',
    value: Themes.PUREVALUE_EN,
    domain: 'purevalue.pl',
  },
  {
    label: 'Avatar International',
    value: Themes.AVATARINTERNATIONAL,
    domain: 'avatarrecruit.co.uk',
  },
  {
    label: 'People More',
    value: Themes.PEOPLEMORE,
    domain: 'peoplemore.pl',
  },
];

const languagesSchema = object({
  level: string().defined(),
  language: string().defined(),
});
export type CvContextLanguages = InferType<typeof languagesSchema>;

const educationSchema = object({
  degree: string().defined(),
  period: string().nullable().defined(),
  school: string().defined(),
});
export type CvContextEducation = InferType<typeof educationSchema>;

const workExperienceApiSchema = object({
  role: string().defined(),
  period: string().defined(),
  periodTime: number().nullable().defined(),
  tasks: array().of(string().defined()).required(),
  technologies: array().of(string().defined()).required(),
  experienceSummary: string().defined(),
  industry: string().defined(),
  companyName: string().defined(),
  name: string().defined(),
});
export type CvContextWorkExperienceApi = InferType<
  typeof workExperienceApiSchema
>;
export type CvContextWorkExperience = Omit<
  CvContextWorkExperienceApi,
  'technologies'
> & { technologies: { label: string; value: string }[] };

const projectSchema = object({
  name: string().defined(),
  client: string().defined(),
  description: string().defined(),
  period: string().defined(),
  skills: array().of(string().defined()).required(),
  tasks: array().of(string().defined()).defined(),
  role: string().defined(),
  industry: string().defined(),
});

export type CvContextProjectSchemaApi = InferType<typeof projectSchema>;

export type CvContextProject = Omit<CvContextProjectSchemaApi, 'skills'> & {
  skills: { label: string; value: string }[];
};
const certificatesSchema = object({
  name: string().defined(),
  additionalInfo: string().defined(),
  date: string().defined(),
});

export type CVContextCertificate = InferType<typeof certificatesSchema>;

const CvParserApiSchema = object({
  location: string(),
  softSkills: array().of(string().required()).required(),
  hardSkills: array().of(string().required()).required(),
  languages: array().of(languagesSchema).required(),
  yearsOfWorkingExperience: string().required(),
});
export type CvParserApi = InferType<typeof CvParserApiSchema>;

const CvContextApiSchema = object({
  id: number().defined(),
  name: string().defined(),
  jobTitle: string().defined(),
  location: string().defined(),
  salaryExpectations: string().defined(),
  availability: string().defined(),
  summary: string().defined(),
  softSkills: array().of(string().required()).required(),
  hardSkills: array()
    .of(
      object({
        skillName: string().nullable().defined(),
        experienceYears: string().nullable().defined(),
        experienceLevel: string().defined(),
        experienceYearsReason: string().defined(),
      }).defined()
    )
    .required(),
  hardSkillsNotConfirmed: array().of(string().defined()).defined(),
  languages: array().of(languagesSchema).required(),
  education: array().of(educationSchema).required(),
  workExperience: array().of(workExperienceApiSchema).required(),
  projects: array().of(projectSchema).required(),
  yearsOfWorkingExperience: string().defined(),
  certificates: array().of(certificatesSchema).defined(),
  companyLogo: string().defined(),
  customData: object({ html: string().defined() }).defined(),
  publicId: string().defined(),
  askForFeedback: boolean().required(),
  isTooLong: boolean().required(),
  originalCvFileUrl: string().defined(),
  language: string().defined(),
  offerTitle: string(),
  offerDescription: string(),
  fieldsToAdjustToOffer: array()
    .of(string().oneOf(Object.values(AdjustToOfferFields)).required())
    .required(),
  status: object({
    FAILED: array().of(string().defined()).defined(),
    PROCESSING: array().of(string().defined()).defined(),
    READY: array().of(string().defined()).defined(),
  }).defined(),
});
export type CvContextApi = InferType<typeof CvContextApiSchema>;

export type CvContext = ReturnType<typeof transformCVConstructResponse>;

export const CvContextListElementSchema = z.object({
  id: z.number(),
  name: z.string(),
  status: z.object({
    FAILED: z.array(z.string()),
    PROCESSING: z.array(z.string()),
    READY: z.array(z.string()),
  }),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  contextSourceName: z.string(),
  publicId: z.string(),
  isTooLong: z.boolean(),
  language: z.string(),
});

const UserCvUsageDataSchema = object({
  counter: number().nullable().defined(),
  limit: number().nullable().defined(),
  currentMonthlyUsageStart: string(),
  currentMonthlyUsageLastUse: string(),
  subscriptionEnd: string(),
  subscriptionCancelAt: string().nullable(),
});

export type CvContextListElement = z.infer<typeof CvContextListElementSchema>;
const CandidateSummarySchema = object({
  name: string().defined(),
  location: string().defined(),
  languages: array().of(languagesSchema).required(),
  skills: array()
    .of(
      object({
        skill: string().required(),
        yearsOfExperience: number().required(),
      }).required()
    )
    .required(),
});

const transformCVConstructResponse = (data: CvContextApi) => {
  const filterUniqueFirstValues = <T extends { skillName: string | null }>(
    arr: T[]
  ): T[] => {
    const uniqueValuesSet = new Set<string | null>();

    return arr.filter(({ skillName }) => {
      if (uniqueValuesSet.has(skillName)) {
        return false;
      }
      uniqueValuesSet.add(skillName);
      return true;
    });
  };

  return {
    ...data,
    customData: data.customData.html,
    softSkills: data.softSkills.map((skill) => ({
      label: skill,
      value: skill,
    })),
    hardSkills: filterUniqueFirstValues(data.hardSkills).map(
      ({
        experienceLevel,
        experienceYears,
        skillName,
        experienceYearsReason,
      }): {
        experienceLevel: string;
        skillName: string | null;
        experienceYears: number | null;
        experienceYearsReason: string;
      } => ({
        experienceLevel,
        skillName,
        experienceYears: Number(experienceYears),
        experienceYearsReason,
      })
    ),
    workExperience: data.workExperience.map((experience) => ({
      ...experience,
      technologies: experience.technologies.map((skill) => ({
        label: skill,
        value: skill,
      })),
    })),
    education: data.education.map((education) => ({
      ...education,
      period: education.period ?? '',
    })),
    projects: data.projects.map((project) => ({
      ...project,
      skills: project.skills.map((skill) => ({
        label: skill,
        value: skill,
      })),
    })),
  };
};

const transformCVContextToApi = (
  data: Partial<CvContext>
): Partial<CvContextApi> => ({
  ...data,
  customData: { html: data.customData ?? '' },
  softSkills: data.softSkills?.map(({ value }) => value),
  hardSkills: data.hardSkills?.map(
    ({
      skillName,
      experienceLevel,
      experienceYears,
      experienceYearsReason,
    }) => ({
      skillName,
      experienceLevel,
      experienceYears:
        !experienceYears || Number.isNaN(experienceYears)
          ? '0'
          : experienceYears?.toString(),
      experienceYearsReason,
    })
  ),
  workExperience: data.workExperience?.map((experience) => ({
    ...experience,
    technologies: experience.technologies.map(({ value }) => value),
    periodTime:
      typeof experience.periodTime !== 'number' ? null : experience.periodTime,
  })),
  projects: data.projects?.map((project) => ({
    ...project,
    skills: project.skills.map(({ value }) => value),
  })),
});

const candidateComparisonSchema = object({
  name: string().defined(),
  location: string().defined(),
  languages: array().of(languagesSchema).required(),
  skills: array()
    .of(
      object({
        skill: string().required(),
        yearsOfExperience: number().required(),
      }).required()
    )
    .required(),
});

export const patchCVContext = async ({
  id,
  formData: { ...formData },
}: {
  id: number;
  formData: Partial<CvContext>;
}) => {
  const { data } = await authService.patch(
    apiRoutes.cvGenerator.context.detail(id)._root,
    Object.fromEntries(
      Object.entries({
        ...transformCVContextToApi(formData),
      }).filter(
        ([key]) =>
          ![
            ...(formData.status?.PROCESSING ?? []),
            ...(formData.status?.FAILED ?? []),
          ]?.includes(key)
      )
    )
  );
  const validatedData = await CvContextApiSchema.validate({
    ...data,
    loadingSections: ['name', 'jobTitle'],
  });
  return transformCVConstructResponse(validatedData);
};

export const getCandidateSummary = async ({
  id,
  ...data
}: {
  id: number;
  offerText: string;
  recruiteeId: string;
}) => {
  const { data: responseData } = await authService.post(
    apiRoutes.cvGenerator.compareWithOffer(id),
    data
  );
  return CandidateSummarySchema.validate(responseData);
};

export const getCvContexts = async ({
  offset,
  pageSize,
  search,
  id,
}: {
  offset?: number;
  pageSize?: number;
  search?: string;
  id?: number | number[];
}) => {
  const { data } = await authService.get(apiRoutes.cvGenerator.context._root, {
    params: {
      limit: pageSize,
      offset,
      search,
      id,
    },
    paramsSerializer: (params) => {
      return QueryString.stringify(params, { arrayFormat: 'repeat' });
    },
  });
  return DjangoDataZodSchema(CvContextListElementSchema).parse(data);
};

export const getCvContext = async (
  id: string | number,
  config?: AxiosRequestConfig<any>
) => {
  const { data } = await authService.get(
    apiRoutes.cvGenerator.context.detail(id)._root,
    config
  );
  const validData = await CvContextApiSchema.validate(data);

  return transformCVConstructResponse(validData);
};

export const deleteCVContexts = async (ids: number[]) => {
  return Promise.all(
    ids.map((id) =>
      authService.delete(apiRoutes.cvGenerator.context.detail(id)._root)
    )
  );
};

export const postRetryCVContext = async (id: number) => {
  return authService.post(apiRoutes.cvGenerator.context.retry(id));
};

export const postCompareWithOffer = async ({
  id,
  offerText,
  recruiteeId,
}: {
  id: number;
  offerText: string;
  recruiteeId: string;
}) => {
  const { data } = await authService.post(
    apiRoutes.cvGenerator.compareWithOffer(id),
    {
      offerText,
      recruiteeId: recruiteeId || undefined,
    }
  );
  return candidateComparisonSchema.validate(data);
};

export const postCvToTranslate = async ({
  id,
  language,
}: {
  id: number;
  language: TranslateLanguages;
}) => {
  const { data } = await authService.post(
    apiRoutes.cvGenerator.context.detail(id).translate,
    {
      language,
    }
  );
  const validatedData = await CvContextApiSchema.validate(data);
  return transformCVConstructResponse(validatedData);
};
export type DownloadCVFormat = 'PDF' | 'DOCX' | 'JSON';
export const downloadCV = async ({
  id,
  type,
  template,
  visible,
  areGroupedSkills,
  signal,
  hideSkillsYearsOfExp,
}: {
  id: number;
  type: DownloadCVFormat;
  template: Themes;
  visible?: string[];
  areGroupedSkills?: boolean;
  hideSkillsYearsOfExp?: boolean;
  signal?: AbortSignal;
}) => {
  return authService.get<Blob>(
    apiRoutes.cvGenerator.context.detail(id).download,

    {
      params: {
        type,
        template,
        visibility: visible,
        are_grouped_skills: areGroupedSkills,
        hide_skills_years_of_exp: hideSkillsYearsOfExp,
      },
      signal,
      paramsSerializer: (params) => {
        return QueryString.stringify(params, { arrayFormat: 'repeat' });
      },
      responseType: 'blob',
    }
  );
};
export const postFeedback = async ({
  id,
  data,
}: {
  id: number;
  data: { [key: string]: boolean };
}) => {
  const modifiedData =
    data.nothingNoteworthy === true
      ? Object.fromEntries(
          Object.keys(data).map((key) => [key, key === 'nothingNoteworthy'])
        )
      : data;
  return authService.post(apiRoutes.cvGenerator.feedback, {
    cvContext: id,
    ...modifiedData,
  });
};

export const getUserCvUsageData = async () => {
  const { data } = await authService.get(apiRoutes.cvGenerator.userUsageData);
  return UserCvUsageDataSchema.validate(data);
};

export const postAdjustToOffer = ({
  id,
  offerTitle,
  offerDescription,
  fieldsToAdjustToOffer,
}: {
  id: number;
  offerDescription?: string;
  offerTitle?: string;
  fieldsToAdjustToOffer: string[];
}) =>
  authService.post(apiRoutes.cvGenerator.context.detail(id).adjustToOffer, {
    offerDescription,
    offerTitle,
    fieldsToAdjustToOffer,
  });

export const postCVContextFromCandidateId = ({
  candidateId,
  forceGenerate,
  language,
  offerDescription,
  offerTitle,
  fieldsToAdjustToOffer,
  skipAnonymization,
}: {
  language: string;
  offerDescription?: string;
  offerTitle?: string;
  fieldsToAdjustToOffer: string[];
  forceGenerate: boolean;
  skipAnonymization: boolean;
  candidateId: number;
}) => {
  return authService.post(
    apiRoutes.cvGenerator.context.fromCandidateId(candidateId),
    {
      language,
      offerDescription,
      offerTitle,
      fieldsToAdjustToOffer,
    },
    {
      params: {
        skip_anonymization: skipAnonymization,
        force_generate: forceGenerate,
      },
    }
  );
};

export const postGroupSkills = async (id: number) => {
  const { data } = await authService.post(
    apiRoutes.cvGenerator.context.detail(id).groupSkills
  );
  const validatedData = await CvContextApiSchema.validate(data);
  return transformCVConstructResponse(validatedData);
};
