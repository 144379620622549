import { captureException, withScope } from '@sentry/react';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import router from 'next/router';
import toast from 'react-hot-toast';

type AuthFormData = {
  email: string;
};

const isAuthFormData = (object: unknown): object is AuthFormData => {
  return (
    !!object &&
    typeof object === 'object' &&
    typeof (object as Record<string, any>).email === 'string'
  );
};

const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onError: (err, variables, _context, mutation) => {
      if (
        !mutation.meta?.silent &&
        !(
          Array.isArray(mutation.meta?.silentStatus)
            ? mutation.meta?.silentStatus
            : []
        )?.includes(err instanceof AxiosError ? err.response?.status : null)
      )
        onMutationErrorToast(err, variables);
      if (
        err instanceof AxiosError &&
        err.response?.status === 401 &&
        err.response.data?.detail !== 'Token is blacklisted'
      )
        return;
      withScope((scope) => {
        scope.setContext('mutation', {
          mutationId: mutation.mutationId,
          variables: mutation.state.variables,
          mutationKey: mutation.options.mutationKey,
        });
        if (mutation.options.mutationKey) {
          scope.setFingerprint([
            ...Array.from(mutation.options.mutationKey),
            ...(err instanceof AxiosError && err.response
              ? [err.response.status.toString()]
              : []),
          ] as string[]);
        }
        const errMethod = (
          err instanceof AxiosError ? err.config?.method ?? '' : ''
        ).toUpperCase();
        const errStatusCode =
          err instanceof AxiosError ? err.response?.status : 'unknown';
        const rethrowError = new Error(
          `${errMethod} ${
            mutation.options.mutationKey || 'unknown mutation key'
          } failed with status code ${errStatusCode}`,
          { cause: err }
        );
        captureException(rethrowError);
      });
    },
  }),
  queryCache: new QueryCache({
    onError: (err, query) => {
      if (
        !query.meta?.silent &&
        !(
          Array.isArray(query.meta?.silentStatus)
            ? query.meta?.silentStatus
            : []
        )?.includes(err instanceof AxiosError ? err.response?.status : null)
      )
        onQueryErrorToast(err);
      if (
        err instanceof AxiosError &&
        err.response?.status === 401 &&
        err.response.data?.detail !== 'Token is blacklisted'
      )
        return;
      withScope((scope) => {
        scope.setContext('query', { queryHash: query.queryHash });
        scope.setFingerprint([
          query.queryHash.replaceAll(/[0-9]/g, '0'),
          ...(err instanceof AxiosError && err.response
            ? [err.response.status.toString()]
            : []),
        ]);

        const errMethod = (
          err instanceof AxiosError ? err.config?.method ?? '' : ''
        ).toUpperCase();
        const errStatusCode =
          err instanceof AxiosError ? err.response?.status : 'unknown';
        const rethrowError = new Error(
          `${errMethod} ${query.queryHash} failed with status code ${errStatusCode}`,
          { cause: err }
        );
        captureException(rethrowError);
      });
    },
  }),
});

export default queryClient;

const onMutationErrorToast = (error: unknown, data: unknown) => {
  if (error instanceof AxiosError) {
    if (error.response?.status === 442 && isAuthFormData(data))
      return router.push({
        pathname: '/dashboard/auth/register/activate',
        query: { retry: data.email },
      });
    if (error.response?.data?.detail)
      return toast.error(error.response?.data?.detail);
  }
  toast.error('Something went wrong');
};

const onQueryErrorToast = (error: unknown) => {
  if (error instanceof AxiosError && error.response?.data?.detail)
    return toast.error(error.response?.data?.detail);
  toast.error('Something went wrong');
};
