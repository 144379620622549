import { useEffect, useState } from 'react';

import { useClient } from './useClient';

type Clickio =
  | {
      cls_val: string;
      cls_ttl: number;
    }
  | undefined;

const monkeyPatchLocalStorage = (localStorageKey: string) => {
  const originalSetItem = localStorage.setItem;
  localStorage.setItem = function (key, value) {
    originalSetItem.call(this, key, value);
    if (key === localStorageKey) {
      window.dispatchEvent(new Event('localStorageChanged'));
    }
  };
};

export const useClickioConsents = () => {
  const { isClient } = useClient();

  const parseClickioStorage = () => {
    if (!isClient) return;
    const clickioStorageJSON = localStorage.getItem('__lxG__consent__v2');
    return clickioStorageJSON ? JSON.parse(clickioStorageJSON) : undefined;
  };

  const [clickioStorage, setClickioStorage] = useState<Clickio | null>(null);

  useEffect(() => {
    setClickioStorage(parseClickioStorage());
  }, [isClient]);

  useEffect(() => {
    if (!isClient) return;
    monkeyPatchLocalStorage('__lxG__consent__v2');
    const handleStorageChange = () => {
      setClickioStorage(parseClickioStorage());
    };
    window.addEventListener('localStorageChanged', handleStorageChange);
    return () => {
      window.removeEventListener('localStorageChanged', handleStorageChange);
    };
  }, [isClient]);

  const clsVal = clickioStorage?.cls_val;
  const clsTtl = clickioStorage?.cls_ttl;

  const consentBits = clsVal
    ?.split('|')[14]
    .split('')
    .map((x) => parseInt(x, 10));

  const consents = {
    functionality: !!consentBits?.[0],
    analytics: !!consentBits?.[1],
    additionalFunctionality: !!consentBits?.[2],
    contentPersonalization: !!consentBits?.[3],
    securityFraudPrevention: !!consentBits?.[4],
    personalizingAndMeasuringTheEffectivenessOfAdvertising: !!consentBits?.[5],
    adPersonalization: !!consentBits?.[6],
  };

  const isConsentValid = clsTtl ? Date.now() < clsTtl : false;

  return {
    consents,
    isConsentValid,
    isConsentReady: isClient && !!consentBits,
  };
};
