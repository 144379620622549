import Clarity from '@microsoft/clarity';
import { useEffect } from 'react';

import { useClickioConsents } from './useClickioConsents';
import { useClient } from './useClient';

export const useClarity = (projectId?: string) => {
  const { consents, isConsentValid, isConsentReady } = useClickioConsents();
  const { isClient } = useClient();
  const canEnableClarity =
    consents.analytics && consents.additionalFunctionality && isConsentValid;
  useEffect(() => {
    if (!isClient || !projectId || !isConsentReady) return;

    Clarity.init(projectId);
    Clarity.consent(canEnableClarity);
  }, [isClient, canEnableClarity, isConsentReady]);
};
