import { createQueryKeys } from '@lukemorales/query-key-factory';
import type { PaginationState } from '@tanstack/react-table';

import {
  getExternalCandidate,
  getExternalCandidates,
  getExternalCandidatesCollections,
  getGithubRepositories,
  getImportErrorsData,
  getImportProgress,
  getMoreRows,
} from '@/services/externalCandidates';

export const externalCandidates = createQueryKeys('externalCandidates', {
  list: ({
    search,
    pagination,
    collections = [],
    salary_order,
    target_currency,
    target_period,
    filtersId,
    showSuggestedCandidates,
    filters,
  }: {
    pagination?: PaginationState;
  } & Omit<
    Parameters<typeof getExternalCandidates>[0],
    'offset' | 'pageSize'
  >) => ({
    queryKey: [
      {
        search,
        pagination,
        collections,
        salary_order,
        target_currency,
        target_period,
        filtersId,
        showSuggestedCandidates,
        filters,
      },
    ],
    queryFn: ({ pageParam }) =>
      getExternalCandidates({
        collections,
        offset:
          (pagination?.pageIndex ?? pageParam) * (pagination?.pageSize ?? 10),
        pageSize: pagination?.pageSize ?? 10,
        search,
        salary_order,
        target_currency,
        target_period,
        filtersId,
        showSuggestedCandidates,
        filters,
      }),
  }),
  detail: (id: number) => ({
    queryKey: [id],
    queryFn: () => getExternalCandidate(id),
  }),
  collections: {
    queryKey: null,
    queryFn: getExternalCandidatesCollections,
  },
  csvImportProgress: (id: number) => ({
    queryKey: [id],
    queryFn: () => getImportProgress(id),
  }),
  getMoreRows: (
    id: number,
    isHeader?: boolean,
    pagination?: PaginationState
  ) => ({
    queryKey: [pagination, isHeader, id],
    queryFn: ({ pageParam }) =>
      getMoreRows({
        isHeader,
        id,
        offset:
          (pagination?.pageIndex ?? pageParam ?? 0) *
          (pagination?.pageSize ?? 10),
        pageSize: pagination?.pageSize ?? 10,
      }),
  }),
  csvErrorData: (id: number) => ({
    queryKey: [id],
    queryFn: () => getImportErrorsData(id),
  }),
  githubRepositories: (id: number, pagination?: PaginationState) => ({
    queryKey: [id, pagination],
    queryFn: ({ pageParam }) =>
      getGithubRepositories({
        id,
        pageSize: pagination?.pageSize ?? 10,
        offset:
          (pagination?.pageIndex ?? pageParam ?? 0) *
          (pagination?.pageSize ?? 10),
      }),
  }),
});
