import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type ReactStyleStateSetter<T> = T | ((prev: T) => T);

type UtmParamsState = {
  utmParams: Record<string, string | undefined>;
  setUtmParams: (
    utmParams: ReactStyleStateSetter<Record<string, string | undefined>>
  ) => void;
};

export const useUtmParamsSession = create<UtmParamsState>()(
  persist(
    (set, get) => ({
      utmParams: get()?.utmParams,
      setUtmParams: (newUtmParams) => {
        if (typeof newUtmParams === 'function')
          return set({
            utmParams: (newUtmParams as Function)(get()?.utmParams),
          });
        return set({ utmParams: newUtmParams });
      },
    }),
    {
      name: 'utm-params',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
