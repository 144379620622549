import { createQueryKeys } from '@lukemorales/query-key-factory';

import {
  getResumeAnalyzer,
  getResumesCredits,
} from '@/services/resume-checker';

export const resumeChecker = createQueryKeys('resumeChecker', {
  detail: (id: string) => ({
    queryKey: [id],
    queryFn: () => getResumeAnalyzer(id),
  }),
  credits: {
    queryKey: null,
    queryFn: () => getResumesCredits(),
  },
});
