import { createQueryKeys } from '@lukemorales/query-key-factory';

import { getReasoningSearch } from '@/services/reasoningSearch';

export const reasoningSearch = createQueryKeys('reasoningSearch', {
  detail: (id?: number) => ({
    queryFn: () => (id ? getReasoningSearch(id) : undefined),
    queryKey: ['reasoningSearch', id],
  }),
});
