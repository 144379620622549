import { z } from 'zod';

import authService from './auth/auth';
import { apiRoutes } from './config';

const statusSchema = z.union([
  z.literal('FAILED'),
  z.literal('PROCESSING'),
  z.literal('READY'),
]);

export const recruiterChoiceSchema = z.enum(['MIKE', 'VIKI', 'CHRIS']);
export type RecruiterChoice = z.infer<typeof recruiterChoiceSchema>;

export type BuyResumeChecking =
  | {
      publicId?: string;
      cvFile: File;
      recruiterChoice: RecruiterChoice;
    }
  | {
      publicId: string;
      cvFile?: File;
      recruiterChoice: RecruiterChoice;
    };

const resumeAnalyzerSchema = z.object({
  generalStatus: statusSchema,
  rate: z.object({
    overallRate: z.number(),
    contentRate: z.number(),
    styleRate: z.number(),
  }),
  sectionsStatus: z.object({
    textExtractionStatus: statusSchema,
    textProcessingStatus: statusSchema,
    personalInfoStatus: statusSchema,
    skillsStatus: statusSchema,
    languagesStatus: statusSchema,
    educationStatus: statusSchema,
    experienceStatus: statusSchema,
    certificatesStatus: statusSchema,
    projectsStatus: statusSchema,
  }),
  sections: z.array(
    z.object({
      rate: z.number(),
      name: z.string(),
      details: z.array(
        z.object({
          name: z.string(),
          rate: z.number(),
          explanation: z.string(),
        })
      ),
    })
  ),
});

export type ResumeAnalyzer = z.infer<typeof resumeAnalyzerSchema>;

export const postResumeAnalyzer = async ({
  file,
  utmParams,
}: {
  file: File | Blob;
  utmParams: Record<string, string | undefined>;
}) => {
  const res = await authService.post(
    apiRoutes.resume.fromFile,
    { cv_file: file, utm_params: utmParams },
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );
  if (res.status === 402) {
    return z.object({ url: z.string() }).parse(res.data);
  }
  return z.object({ publicId: z.string() }).parse(res.data);
};

export const getResumeAnalyzer = async (id: string) => {
  const { data } = await authService.get(apiRoutes.resume.detail(id));
  return resumeAnalyzerSchema.parse(data);
};

export const getResumesCredits = async () => {
  const { data } = await authService.get(apiRoutes.resume.usage);
  return z
    .object({
      credits: z.number(),
    })
    .parse(data);
};

export const buyResumeChecking = async ({
  publicId,
  cvFile,
  recruiterChoice,
}: BuyResumeChecking) => {
  const { data } = await authService.post(
    apiRoutes.resume.buyResumeChecking,
    {
      public_id: publicId,
      cv_file: cvFile,
      recruiter_choice: recruiterChoice,
    },
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  );
  return z.object({ url: z.string() }).parse(data);
};
