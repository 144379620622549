import { createQueryKeys } from '@lukemorales/query-key-factory';

import {
  getCities,
  getCitySuggestions,
  getCountries,
  getCountrySuggestions,
  getLanguages,
  getSkills,
} from '@/services/common';

export const common = createQueryKeys('common', {
  languages: {
    queryKey: null,
    queryFn: getLanguages,
  },
  skills: {
    queryKey: null,
    queryFn: getSkills,
  },
  cities: (country: Parameters<typeof getCities>[0]) => ({
    queryKey: [{ country }],
    queryFn: () => getCities(country),
  }),
  suggestedCities: ({
    limit,
    offset,
    query,
    country,
  }: Parameters<typeof getCitySuggestions>[0]) => ({
    queryKey: [{ limit, offset, query, country }],
    queryFn: () => getCitySuggestions({ limit, offset, query, country }),
  }),
  suggestedCountries: ({
    limit,
    offset,
    query,
  }: Parameters<typeof getCountrySuggestions>[0]) => ({
    queryKey: [{ limit, offset, query }],
    queryFn: () => getCountrySuggestions({ limit, offset, query }),
  }),
  countries: (continent: Parameters<typeof getCountries>[0]) => ({
    queryKey: [{ continent }],
    queryFn: () => getCountries(continent),
  }),
});
