import { createQueryKeys } from '@lukemorales/query-key-factory';

import { getApiKeys } from '@/services/apiKey';

export const apiKeys = createQueryKeys('apiKeys', {
  list: {
    queryFn: getApiKeys,
    queryKey: null,
  },
});
