import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { z } from 'zod';

import { useUtmParamsSession } from '@/session_storage/useUtmParamsSession';

import { useClient } from './useClient';

export const useCatchUtmParams = () => {
  const { isClient } = useClient();
  const { query, isReady } = useRouter();
  const { setUtmParams } = useUtmParamsSession();

  const newUtmParams = {
    utm_source: z.string().optional().parse(query.utm_source),
    utm_medium: z.string().optional().parse(query.utm_medium),
    utm_campaign: z.string().optional().parse(query.utm_campaign),
  };

  const nonEmptyUtmParams = Object.fromEntries(
    Object.entries(newUtmParams).filter(([_, value]) => value)
  );

  useEffect(() => {
    if (!isClient) return;
    setUtmParams((prev) => {
      return { ...prev, ...nonEmptyUtmParams };
    });
  }, [isReady, JSON.stringify(nonEmptyUtmParams), isClient]);
};
